"use client";
import React, { useState } from "react";

import Image from "next/image";
import Link from "next/link";

import { CgMenuRound } from "react-icons/cg";
import { IoCloseCircleOutline } from "react-icons/io5";

import MobileNav from "./MobileNav";
import ThemeButton from "../ThemeButton";

import styling from "./HomeHeader.module.scss";

interface HeaderProps {
  theme?: string;
  isLoggedIn?: boolean;
}

const Header: React.FC<HeaderProps> = ({ theme, isLoggedIn }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  return (
    <header
      className={`${styling.header} ${
        theme === "dark" ? styling.darkMode : ""
      }`}
    >
      <div className={styling.sideLeft}>
        <div>
          <Link href="/">
            <span>
              <Image
                src="https://ik.imagekit.io/vinnie/BLOG/Vinnie-tec_62aUaX5Hu.png?updatedAt=1684410998966"
                alt="Vinnietec-Logo"
                width={450}
                height={450}
              />
            </span>
            <span>
              <strong>Blog</strong>
            </span>
          </Link>
        </div>
        <nav>
          <ul>
            <li>
              <Link href="https://vinnietec.netlify.app/">Portfolio</Link>
            </li>
            {isLoggedIn ? (
              <li>
                <Link
                  href="/user-profile"
                  style={{
                    textDecoration: "none",
                    color: "rgb(4, 2, 69)",
                    boxShadow: "0 -1px 1px 1px rgb(4, 2, 69)",
                    padding: "5px 10px",
                    borderRadius: "3px",
                    display: "inline-block",
                    fontSize: ".8rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Your Profile
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </nav>
      </div>
      <div className={styling.sideRight}>
        <nav>
          {isLoggedIn ? (
            <button>
              <Link href="/blog">Go to Blog</Link>
            </button>
          ) : (
            <div className={styling.sideRight__authNav}>
              <ul>
                <li>
                  <Link href="/auth/signin">Sign In</Link>
                </li>
                <li>
                  <Link href="/auth/signup">Sign Up</Link>
                </li>
              </ul>
            </div>
          )}
        </nav>
        <span>
          <ThemeButton size={25} />
        </span>
        <span
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {isNavExpanded ? (
            <IoCloseCircleOutline size={30} />
          ) : (
            <CgMenuRound size={30} />
          )}
        </span>
      </div>
      {isNavExpanded && (
        <MobileNav
          setIsNavExpanded={setIsNavExpanded}
          isNavExpanded={isNavExpanded}
          isLoggedIn={isLoggedIn}
        />
      )}
    </header>
  );
};

export default Header;
