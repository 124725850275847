import React from "react";
import Link from "next/link";

import styling from "./MobileNav.module.scss";

interface MobileNavProps {
  isNavExpanded: boolean;
  setIsNavExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isLoggedIn?: boolean;
}

const MobileNav: React.FC<MobileNavProps> = ({
  isNavExpanded,
  setIsNavExpanded,
  isLoggedIn,
}) => {
  return (
    <nav className={`${styling.mobilenav} ${isNavExpanded ? "visible" : ""}`}>
      <ul
        className={"mobilenav__list"}
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <li>
          <Link href="https://vinnietec.netlify.app/">Portfolio</Link>
        </li>
        {isLoggedIn ? (
          <li>
            <Link href="/user-profile" style={{ whiteSpace: "nowrap" }}>
              Your Profile
            </Link>
          </li>
        ) : (
          ""
        )}
      </ul>
    </nav>
  );
};

export default MobileNav;
