"use client";

import { RootState } from "./GlobalRedux/Store";
import { useSelector } from "react-redux";

import Header from "./(Global)/Components/HomeHeader";
import styling from "./global-error.module.scss"; 

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const theme: string = useSelector(
    (state: RootState) => state.theme.themeValue
  );
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <html>
      <body>
        <Header theme={theme} isLoggedIn={isLoggedIn} />
        <div className={styling.errorContainer}>
          <h2>Oh no! Something went wrong! 🌶️🔥</h2>
          <p>Looks like we`&apos;ve hit a spicy snag! Here`&apos;s what happened:</p>
          <p className={styling.errorMessage}>{error.message}</p>
          <p>
            But don`&apos;t worry, our team is sprinkling some magic chili powder on
            it!
          </p>
          <button className={styling.retryButton} onClick={reset}>
            Give it another shot!
          </button>
        </div>
      </body>
    </html>
  );
}
